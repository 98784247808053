import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import ButtonGridComp from "./ButtonGridComp";
import HistoricalReading from "./HistoricalReading";

import TimeRangeBg from "./TimeRangeBg";
import AverageBg from "./AverageBg";

import HyposHyperBg from "./HyposHyperBg";
import BgReading from "./BgReading";


import BgStats from "./BgStats";
import BgRating from "./BgRating";
import ExportData from "../../Export/ExportData";

import {useDispatch, useSelector} from "react-redux";
import {fetchBgData} from "../../../../redux/actions/micro-apps/blood-glucose";
import {getDevices} from "../../../../redux/actions/device";
import moment from "moment";
import {Spin, Menu, Dropdown, Button, DatePicker} from "antd";
import * as _ from "lodash";
import Events from "../../events-components";
import BgAvgMeal from "../bg-avg-meal";
import { default as Titles } from "../../../../utils/document-titles";



const { RangePicker } = DatePicker;


function BloodGlucoseComp(props) {
  document.title = Titles.bloodGlucose;

  const data = useSelector((state) => {return (state.bloodGlucose)});

  const unit = useSelector((state)=> state.devices.device!== undefined?state.devices.device:null);
  const [filterText, setFilterText] = useState("Choose Data Range");
  const [filter, setFilter] = useState("");
  const [tfHistoricalReadings, setTfHistoricalReadings] = useState([]);
  const [tfBgReadings, setTfBgReadings] = useState([]);
  const [tfAvgBgData, setTfAvgBgData] = useState([]);
  const [tfTimeRange, setTfTimeRange] = useState([]);
  const [showDate, setShowDate] = useState(false);
  const [endDate, setStartDate] = useState("");
  const [startDate, setEndDate] = useState("");
  const [todayFilter, setTodayFilter] = useState();
  const [weekFilter, setWeekFilter] = useState();
  const [monthFilter, setMonthFilter] = useState(true);
  const [rangFilter, setRangeFilter] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [dropMenu, setDropMenu] = useState(false);
  const [rangeBtn, setRangeBtn] = useState(false);
  const [rangeDate, setRangeDate] = useState("");
  const [dateRange,setDateRange]=useState(false);
  const device = useSelector((state) =>{
    return state.devices.devices}
  );

  const dispatch = useDispatch();
  useEffect(()=>{
    setFilter("30days")
  },[])

  //(data.timeAndGlucose);

  useEffect(() => {
    // console.clear();

    if (filter !== "" || startDate !== "") {
      fetchReadings();
      setDropMenu(false);
      setRangeDate("")
    }
    return () => {                                                               // second, we return an anonymous clean up function
      setDateRange(false)
    };
  }, [filter, dateRange,unit]);

  useEffect(() => {
    transformHistoricalReadings();
    setStartDate("")
    setEndDate("")
    // console.clear();

  }, [data.historicalReadings]);

  useEffect(() => {
    transformAverageBgData();
    //console.clear();

  }, [data.averageBg]);

  useEffect(() => {
    transformBgReading();
    //console.clear();
  }, [data.bgReadings]);

  useEffect(() => {
    // // // console.log("startDate",startDate);
  }, [rangeDate]);

  useEffect(() => {
    if (data.timeRange.length > 0) {
      transformTimeRange();
    }
    //console.clear();

  }, [data.timeRange]);
  setTimeout(() => {
    setRangeBtn(true)
    //console.clear();

  }, 1000);
  const fetchReadings = async () => {
    // console.clear();

    let data = {};
    if (filter) {
      data.time = filter;
    }
    if (startDate) {

      data.start_date = endDate;
      data.end_date = startDate;
    }
    if (unit !== null && unit.preferences && unit.preferences.unit !== null) {

      dispatch(fetchBgData(data, unit.preferences.unit));
    }

    if (rangFilter === false){
    let start = findStartDate(filter);

    setFromDate(moment(start).format('D MMM YYYY').toString());
    setToDate(moment(findStartDate()).format('D MMM YYYY').toString());
  } else if (rangFilter === true) {

  }

  };

  const transformTimeRange = () => {
    let labels = ["<3.9", "3.9-4.9", "5.0-8.0", "8.1-13.3", ">13.3"];
    let labelsMgdl = ["<70.24", "70.24-88.3", "90.0-144.1", "145.9-239.5", ">239.5"];
    const keys = Object.keys(data.timeRange[0]);
    const transformedData = keys.map(key => {
      return {
        label: unit.preferences && unit.preferences.unit === 0 ? labels[Number(key)] : labelsMgdl[Number(key)],
        value: data.timeRange[0][key].value
      }
    });

    setTfTimeRange(transformedData);
  };

  const transformHistoricalReadings = () => {
    let transformedData = [];
    //("data.historicalReadings=",data.historicalReadings)
    transformedData = data.historicalReadings

        .map((reading) => {

          return reading.data.map((item, index) => {
const date = new Date(item.year, item.month - 1, reading._id, item.hour, item.minutes);
let obj = {
  date: date,
  reading: item.value["bloodGlucose"],
  time: item.tmie,
};
console.log("OBJECT :", obj);
return obj;
            // if(new Date(startDate).getMonth()<=item.month && new Date(endDate).getMonth()<=item.month){
            //   let date = new Date();
            //   console.log("ITEM :", item, index);
            //   date.setMonth(item.month-1);
            //   date.setDate(reading._id);
            //   date.setHours(item.hour);
            //   date.setMinutes(item.minutes);
            //   date.setYear(item.year);
            //   console.log("DATE ", date, index);
            //   return {
            //     date: date,
            //     reading: item.value["bloodGlucose"],
            //   };
            // }
            // else {
            //   let date = new Date();
            //
            //   date.setMonth(item.month-1);
            //   date.setDate(reading._id);
            //   date.setHours(item.hour);
            //   date.setMinutes(item.minutes);
            //   return {
            //     date: date,
            //     reading: item.value["bloodGlucose"],
            //   };
            // }


          });
        })
        .flat();

    let myArray = _.sortBy(transformedData.flat(), function(dateObj) {

      return new Date(dateObj.date);
    });

    setTfHistoricalReadings(myArray);
  };

  const transformAverageBgData = () => {
    let transformedData = [];
    transformedData = data.averageBg.map((item) => {
      // // // // // console.log("ITEM AVBG", item);
// Before
      // let m = moment().hour(item._id).format("hh A");


// After
      let m = item._id;


      return {
        date: m,
        value: item.avg,
      };
    });
    setTfAvgBgData(
        _.sortBy(transformedData, (dateObj) => new Date(dateObj.date))
    );
  };

  ////("before=", data.bgReadings);
  const transformBgReading = () => {
    let tfBgReadings = data.bgReadings.map((item) => {
      ////("Item",item)



      // Time
      var offset = moment().utcOffset();
      var date = moment.utc(item.time).utcOffset(offset).format('DD-MM-YYYY');
      var time = moment.utc(item.time).utcOffset(offset).format('h:mm:ss A');

      item.takenTime=item.takenTime === 0 ? "Before meal" : item.takenTime === 1 ? "After Meal": item.takenTime === 2 ? "General": "QC";
      item.bgValue = item.value.bloodGlucose;
      item.bgValue = item.bgValue && item.bgValue.toFixed(1);
      item.time =date
      item.clock = time
      return item;
    });
    setTfBgReadings(tfBgReadings);
  };

  function handleMenuClick(e) {
    switch (e.item.props.value) {
      case "today":
        break;
      case "week":
        break;
      case "month":
        break;
      case "range":
        break;
      default:
        break;
    }
  }

  const findStartDate = (filter) => {

    let currentDate = new Date();

    if(filter === "30days") {
      let start = moment().subtract(30, 'days');
      return new Date(start._d);
    }else if(filter==="7days") {
      let start = moment().subtract(7, 'days');

      return new Date(start._d);


    } else if (filter === "today") {

      return new Date();

    } else{

    }

  }
  const findEndDate = () => {
    let currentDate = new Date();
    return (currentDate);
  }

  const dropDownIntervalMenu = ( <Menu onClick = { handleMenuClick } >
      <Menu.Item type = "data-range"
                  value = "today"
                  key = "1" >
         Today
      </Menu.Item>
        <Menu.Item type = "data-range"
                  value = "week"
                  key = "2" >
          Week
        </Menu.Item>
        <Menu.Item type = "data-range"
                  value = "month"
                  key = "3" >
          Month
        </Menu.Item>
        <Menu.Item type = "data-range"
                  value = "range"
                  key = "4" >
          Range
        </Menu.Item>
  </Menu>
      );
      const onStartDateChange=(e)=>{
        // // // console.log("EVENT", e);

          const val=e.target.value

           setStartDate(moment(val).format("YYYY-MM-DD").toString());
           setToDate(moment(val).format("YYYY-MM-DD").toString());
           setRangeDate(moment(val).format("YYYY-MM-DD").toString())


      }
      const onEndDateChange=(e)=>{

        // setFilter("");
          const val=e.target.value
        if(val){

          setEndDate(moment(val).format("YYYY-MM-DD").toString());
        setFromDate(moment(val).format("YYYY-MM-DD").toString());
        }

      }
      const handleDateChange=(val)=>{

        if( val && val.length>0 ){

        setEndDate(val[1].format("YYYY-MM-DD").toString());
        setEndDate(val[0].format("YYYY-MM-DD").toString());
        setToDate(val[1].format("YYYY-MM-DD").toString());
        setFromDate(val[0].format("YYYY-MM-DD").toString());
        }
      }
      const getDaysDiff = (start_date, end_date, date_format = 'YYYY-MM-DD') => {
        const getDateAsArray = (date) => {
          return moment(date.split(/\D+/), date_format);
        }
        return getDateAsArray(end_date).diff(getDateAsArray(start_date), 'days')+1 ;
      }
      const onClickBtnOk=()=>{

        if(startDate!==""){
          setDateRange(true)
        }
        else{

        }

      }

      const printDates=()=>{


      }

  return (<Spin spinning = { data.isLoading } >
      <div className = "bg-gray-200  flex flex-col p-1 lg:p-0 pb-5" >
      {!data.isLoading && (
      <>
      <div>
      <div className="container-fluid mb-2 ">
      <div className="d-flex my-2 mx-3  p-2 pr-1 justify-content-end align-items-center  border-bottom">
       {device.Bg==true || device.Bp==true || device.PO==true || device.Weight==true || device.Temp==true?<ExportData />:null}

      </div>
      <div className="py-3 ">
          <div>
            <div className="d-flex filter-btn p-2 align-items-center justify-content-lg-start filter-area justify-content-sm-center justify-content-center">

              <div className='d-flex flex-lg-row align-items-center flex-column'>
              <div className="d-flex align-items-center justify-content-center day-dates p-2">
              <div className=" text-voyger-purple">
                <h4 className="chart-heading m-0">{filter==="" && startDate!=="" && endDate !=="" ?
                  getDaysDiff(toDate, fromDate)+" days":
                  filter=== "30days"? "30 days":
                  filter=== "14days"? "14 days":
                  filter=== "7days"? "07 days":
                  filter=== "today"?filter : null}
                </h4>
              </div>
              {filter==""?<span>
              <span className="p-2">{toDate? toDate.toString(): 0} </span>
                <span className=""> - </span>
                <span className="p-2"> {fromDate? fromDate.toString(): 0}</span>
              </span>:<span>
              <span className="p-2">{fromDate? fromDate.toString(): 0} </span>
                <span className="p-2"> - </span>
                <span className="p-2"> { toDate? toDate: 0}</span>
              </span>}

              </div>
              <div className="d-flex justify-content-center flex-row mt-md-0 mt-2">
              <button type="button" className={ todayFilter? "bg-filter-btn mx-1 select-active": "bg-filter-btn mx-1"}
                      onClick = {
                        () => {
                          setEndDate("");

                          setEndDate("");
                          setDateRange(false)
                          setShowDate(false);
                          setFilter("today");
                          setFilterText("Today");
                          setTodayFilter(true);
                          setWeekFilter(false);
                          setMonthFilter(false);
                          setRangeFilter(false);
                          setDropMenu(false);

                        }
                      }>
                TODAY
              </button>
              <button type="button" className={ weekFilter? "bg-filter-btn mx-1 select-active": "bg-filter-btn mx-1"} onClick = {
                () => {
                  setEndDate("");

                  setEndDate("");
                  setDateRange(false)
                  setShowDate(false);
                  setFilter("7days");
                  setFilterText("Week");
                  setWeekFilter(true);
                  setTodayFilter(false);
                  setMonthFilter(false);
                  setRangeFilter(false);
                  setDropMenu(false);
                }
              } >
                WEEK
              </button>
              <button type="button"

              className={ monthFilter? "bg-filter-btn mx-1 select-active": "bg-filter-btn mx-1"} onClick = {
                () => {
                  setEndDate("");

                  setEndDate("");
                  setDateRange(false)
                  setShowDate(false);
                  setFilter("30days");
                  setFilterText("Month");
                  setWeekFilter(false);
                  setTodayFilter(false);
                  setMonthFilter(true);
                  setRangeFilter(false);
                  setDropMenu(false);
                }
              }>
                MONTH
              </button>
              <button type="button"
               disabled={rangeBtn?false:true}
              className={ rangFilter? "bg-filter-btn mx-1 select-active": "bg-filter-btn mx-1"} onClick = {
                () => {
                  setFilter("");
                  setFilterText("Range");
                  setShowDate(true);
                  setWeekFilter(false);
                  setTodayFilter(false);
                  setMonthFilter(false);
                  setRangeFilter(true);
                  setDropMenu(true);
                  printDates();

                }
              }>
                RANGE
              </button>

              </div>
              <div className="d-flex text-center mt-3 justify-content-center">

                    <div className={dropMenu ? " d-block mb-3" : " d-none"}>
                <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
                     <div className='d-flex flex-row mb-md-0 mb-2'>
                     <input type="date"
                    onChange={onStartDateChange}
                    ></input>
                    <input type="date"
                    disabled={rangeDate===""?true:false}
                    className="ml-1"
                    onChange={onEndDateChange}
                    min={moment(endDate).format("YYYY-MM-DD")}
                    max={moment().format("YYYY-MM-DD")}
                    ></input>
                       </div>
                    <button className="btn bg-light" onClick={onClickBtnOk}>Go</button>
                </div>

              </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row  mx-auto mr-sm-0 mr-lg-0 ">

          <div className="col-12 col-sm-12 col-lg-7   mt-2 " >
            <div className=" bg-white border-radius-chart reading-sec p-3 row mw-100 m-auto h-100"
              >

              {/*<HistoricalReading margin={true}  dataPoints = { tfHistoricalReadings } renderOn={"blood-glucose"}/>*/}

              <HistoricalReading margin={true} themeType={"bg"} id={"historicChartId"} dataPoints = { tfHistoricalReadings }/>

            </div>
          </div>
          {/* <div className="col-12 col-md-6 col-lg-4  mt-2  d-md-none d-block " >
            <div className=" bg-white border-radius-chart p-3 bg-chart-height">
              <BgRating
                  margin={true}
                  id={"chart"}
                  height="100%"
                  timePercentage = {
                data.timeAndGlucose.inRangePercentage &&
                parseInt(data.timeAndGlucose.inRangePercentage)
              }
                         glucosePercentage = {
                           data.timeAndGlucose.highEventsPercentage &&
                           parseInt(
                               data.timeAndGlucose.highEventsPercentage +
                               data.timeAndGlucose.lowEventsPercentage
                           )
                         }
                         timeDataPoints = {
                           [{
                             label: "In-Range",
                             value: parseInt(data.timeAndGlucose.inRangePercentage),
                           },
                             {
                               label: "Out-Range",
                               value: 100 - parseInt(data.timeAndGlucose.inRangePercentage),
                             },
                           ]
                         }
                         glucoseDataPoints = {
                           [{
                             label: "High-events",
                             value: parseInt(data.timeAndGlucose.highEventsPercentage),
                           },
                             {
                               label: "Low-events",
                               value: parseInt(data.timeAndGlucose.lowEventsPercentage),
                             },
                             {
                               label: "In-Range",
                               value: parseInt(data.timeAndGlucose.inRangePercentage),
                             },
                           ]
                         }/>
            </div>
          </div> */}
          <div className="col-12  col-lg-5 mt-2" >
            <div className=" bg-white h-100 border-radius-chart d-flex justify-content-lg-around justify-content-xl-around flex-column">
            <BgRating
                 id={"chart1"}
                  margin={true}
                  height="60%"
                  width="75%"
                  themeType={"bg"}
                  timePercentage = {
                data.timeAndGlucose.inRangePercentage &&
                parseInt(data.timeAndGlucose.inRangePercentage)
              }
                         glucosePercentage = {
                           data.timeAndGlucose.highEventsPercentage &&
                           parseInt(
                               data.timeAndGlucose.highEventsPercentage +
                               data.timeAndGlucose.lowEventsPercentage
                           )
                         }
                         timeDataPoints = {
                           [{
                             label: "In-Range",
                             value: parseInt(data.timeAndGlucose.inRangePercentage),
                           },
                             {
                               label: "Out-Range",
                               value: 100 - parseInt(data.timeAndGlucose.inRangePercentage),
                             },
                           ]
                         }
                         glucoseDataPoints = {
                           [{
                             label: "High-events",
                             value: data.timeAndGlucose.highEventsPercentage,
                           },
                             {
                               label: "Low-events",
                               value: data.timeAndGlucose.lowEventsPercentage,
                             },
                             {
                               label: "In-Range",
                               value: data.timeAndGlucose.inRangePercentage,
                             },
                           ]
                         }/>
              <BgStats themeType={"bg"} id="bg" unit={ unit !== null && unit.preferences && unit.preferences.unit ? unit.preferences.unit : 0} {...data.bgStats } />
            </div>
          </div>
        </div>
        <div className="row mx-auto mr-sm-0  mt-2">
          <div className="col-12 col-sm-6 col-lg-6" >
            <div className="   bg-white border-radius-chart p-3 " style={{ height: "400px" }}>
              <TimeRangeBg themeType={"bg"} dataPoints = { tfTimeRange }/>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-6 mt-2 mt-md-0" >
            <div className="   bg-white border-radius-chart p-3 " style={{ height: "400px" }}>
              {/*<AverageBg dataPoints = { tfAvgBgData }/>*/}
              <AverageBg themeType={"bg"} id={"vBg"} dataPoints = { tfAvgBgData }/>
            </div>
          </div>
        </div>
        <div className="row mx-auto mr-sm-0  mt-2">
          <div className="col-12 col-sm-6 col-lg-6" >
            <div className="   bg-white border-radius-chart p-3 bg-chart-height" >
              <HyposHyperBg themeType={"bg"} unit={unit !== null && unit.preferences && unit.preferences.unit ? unit.preferences.unit : 0
} {...data.avgPreAndAfterMeal } {...data.hyposAndHypers }/>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-6  mt-2 mt-md-0 " >
            <div className=" bg-white border-radius-chart  p-3 " style={{ height: "400px" }}>
              <div className="actual-data">
              <BgReading themeType={"bg"} dataSource = { tfBgReadings }/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
          </>) }
      </div>
    </Spin>
  );
};
function mapStateToProps (state) {

        return {
          unit: state.devices.device !== "" && state.devices.device !== undefined &&
          state.devices.device.preferences && state.devices.device.preferences!=="" && state.devices.device.preferences.unit?state.devices.device.preferences.unit: null,
        }
    }

export default connect(mapStateToProps)(BloodGlucoseComp);
